import React, { useState } from "react";
import Layout from "../../Layout";
import data from "./data";
import "./style.scss";
import SEO from "../../seo";

export default function TransferCertificatePage() {
  const [filterString, setFilterString] = useState("");

  const handleOnChange = (e) => {
    setFilterString(e.target.value);
  };

  const filteredData = data.filter((item) =>
    item.admissionNo.includes(filterString)
  );

  return (
    <Layout>
      <SEO
        title="Transfer Certificate | Chinmaya Vidyalaya Vasant Vihar"
        description="Download your transfer certificate by submitting your admission number. "
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="transfercertificate">
        <h1 className="heading">Transfer Certificates</h1>

        <div className="transfercertificate__searchcontainer">
          <input
            onChange={handleOnChange}
            className="transfercertificate__search"
            placeholder="Search by Admission Number"
          ></input>
        </div>
        <div className="transfercertificate__table">
          <div className="transfercertificate__table__row transfercertificate__table__row--heading">
            <div className="transfercertificate__table__col1">
              Admission No.
            </div>
            <div className="transfercertificate__table__col2">
              Date Of issue
            </div>
            <div className="transfercertificate__table__col3">Student Name</div>
          </div>
          {filteredData.map((item, i) => (
            <div className="transfercertificate__table__row">
              <div className="transfercertificate__table__col1">
                {item.admissionNo}
              </div>
              <div className="transfercertificate__table__col2">
                {item.date}
              </div>
              <div className="transfercertificate__table__col3">
                {item.name}
              </div>
              <div className="transfercertificate__table__col4">
                <a href={item.link} download>
                  <i className="fas fa-file-download"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
